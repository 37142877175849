import {nextTick, ref} from "vue";
import _ from "lodash";

export function useFormTop(){
    const inputting = ref(false);
    const actionBtn = ref(null);
    const scrollTop = _.debounce(() => {
        nextTick(() => {
            if (inputting.value) return;
            scrollTo(0, 0);
            if (actionBtn.value){
                actionBtn.value.ref.focus();
            }
        })
    },100, {maxWait: 1000})
    return {inputting, actionBtn, scrollTop};
}