<template>
  <el-form :model="formData" label-width="120px" label-position="top" size="default" >
    <div :style="readOnly ? {'height' : '786px'} : {'height' : '826px'}">
      <el-form-item label="发票类型"  >
        <el-radio-group v-model="formData.type" @change="v => {if(readOnly) formData.type = -1 - v}">
          <el-radio border :label="0" >正字发票</el-radio>
          <el-radio border :label="-1" >红字发票</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-row :gutter="10">
        <el-col :md="12">
          <el-form-item label="开票名称">
            <el-input v-model="formData.invoice_name" placeholder="请输入开票名称"  :readonly="readOnly" show-word-limit maxlength="80"   clearable @blur="inputting = false; scrollTop()"  @focus="inputting = true"/>
          </el-form-item>
        </el-col>
        <el-col :md="12">
          <el-form-item label="是否有合同"  v-if="formData.type == 0">
            <el-radio-group v-model="formData.has_contract" @change="v => {if(readOnly) formData.has_contract = !v}">
              <el-radio border :label="true" >有</el-radio>
              <el-radio border :label="false" >无</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="原正字发票对应发票号"  v-else >
            <el-input v-model="formData.invoice_no" placeholder="请输入原正字发票对应发票号"  :readonly="readOnly" show-word-limit  maxlength="80"   clearable @blur="inputting = false; scrollTop()"  @focus="inputting = true"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :md="12">
          <el-form-item label="合同总金额" v-if="formData.type == 0">
            <el-input v-model="formData.total"
                      @blur="inputting = false; scrollTop()"
                      @focus="inputting = true"
                      :readonly="readOnly"
                      placeholder="请输入合同总金额"
                      :formatter="formatter"
                      :parser="parser"
            >
              <template #append>¥</template>
            </el-input>
          </el-form-item>
          <el-form-item label="原正字发票对应开票日期" v-else>
            <el-date-picker
                v-model="formData.invoice_build_time"
                type="date"
                value-format="YYYY-MM-DD"
                placeholder="请选择原正字发票对应开票日期"
                style="width: 100%"
                @blur="inputting = false; scrollTop()"
                @focus="inputting = true"
                :readonly="readOnly"
            />
          </el-form-item>
        </el-col>
        <el-col :md="12">
          <el-form-item label="审计额【非必填】" v-if="formData.type == 0">
            <el-input
                v-model="formData.audit"
                @blur="inputting = false; scrollTop()"
                @focus="inputting = true"
                :readonly="readOnly"
                placeholder="请输入审计额"
                :formatter="formatter"
                :parser="parser"
            >
              <template #append>¥</template>
            </el-input>
          </el-form-item>
          <el-form-item label="原正字发票对应金额" v-else>
            <el-input
                v-model="formData.total"
                @blur="inputting = false; scrollTop()"
                @focus="inputting = true"
                :readonly="readOnly"
                placeholder="请输入原正字发票对应金额"
                :formatter="formatter"
                :parser="parser"
            >
              <template #append>¥</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="本次开具金额" v-if="formData.type == 0">
        <el-input
            v-model="formData.apply"
            @blur="inputting = false; scrollTop()"
            @focus="inputting = true"
            :readonly="readOnly"
            placeholder="请输入本次开具金额"
            :formatter="formatter"
            :parser="parser"
        >
          <template #append>¥</template>
        </el-input>
      </el-form-item>
      <el-form-item label="本次红字发票开具金额【金额为负数】" v-else>
        <el-input
            v-model="formData.apply"
            @blur="inputting = false; scrollTop()"
            @focus="inputting = true"
            :readonly="readOnly"
            placeholder="请输入本次红字发票开具金额"
            :formatter="formatter"
            :parser="parser"
        >
          <template #append>¥</template>
        </el-input>
      </el-form-item>
      <el-form-item label="发票税率" style="margin: 20px 0;">
        <el-radio-group v-model="formData.tax_rate" >
          <el-space wrap >
            <el-radio :label="3"  border size="small">3%</el-radio>
            <el-radio :label="6" border size="small">6%</el-radio>
            <el-radio :label="9" border size="small">9%</el-radio>
            <el-radio :label="13" border size="small">13%</el-radio>
          </el-space>
        </el-radio-group>
        <div style="position:absolute; height: 36px; width: 100%; z-index: 99999; top: -6px" v-if="readOnly"></div>
      </el-form-item>
      <el-form-item >
        <template #label>
          备注【必填】
          <el-switch
              v-model="choosePlaceholder"
              active-text="占位符内容保留"
              inactive-text=""
              @change="switchChange"
              v-if="!readOnly && formData.type == 0"
          />
        </template>
        <el-input v-if="formData.type == 0" :readonly="readOnly" :rows="8" v-model="formData.applicant_comments"   maxlength="500" show-word-limit type="textarea" :placeholder='placeHolder' @blur="inputting = false; scrollTop()" @focus="inputting = true"/>
        <el-input v-else :readonly="readOnly" :rows="8" v-model="formData.applicant_comments"   maxlength="500" show-word-limit type="textarea" placeholder='请输入开具红字发票的原因' @blur="inputting = false; scrollTop()" @focus="inputting = true"/>
      </el-form-item>
      <el-affix position='bottom' :offset="50" :target="window" v-if="!readOnly">
        <el-form-item >
          <el-button color="#626aef"  ref="actionBtn"  @click="$emit('apply')" style="width: 100%" >提交审批</el-button>
        </el-form-item>
      </el-affix>
    </div>
    <slot/>
  </el-form>
</template>

<script setup>
import {defineProps, reactive, defineEmits, ref, onUnmounted} from "vue";
import {useFormTop} from "@/assets/js/formTop";
import {ElMessageBox} from "element-plus";

defineEmits(['apply'])
const props = defineProps(['formData', 'readOnly'])
const formData = reactive(props.formData);
const placeHolder = "请备注以下信息:\n1、工程名称\n2、工程地点\n3、发票类型\n4、无合同原因"
const {inputting, actionBtn, scrollTop} = useFormTop();
const choosePlaceholder = ref(false)

const parser = (value) => value.replace(/\$\s?|(,*)/g, '');
const formatter = (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const switchChange = v => {
  ElMessageBox.confirm(
      v ? '确认设定占位符？' : '确认清空内容？',
      '提醒',
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }
    )
    .then(() => {
        if (v){
          formData.applicant_comments = "1、工程名称：\n2、工程地点：\n3、发票类型：\n4、无合同原因："
        }
        else {
          formData.applicant_comments = '';
        }
    })
    .catch(() => {
      choosePlaceholder.value = ! choosePlaceholder.value;
    })
}

onUnmounted(() => {
   if (formData.applicant_comments === '1、工程名称：\n2、工程地点：\n3、发票类型：\n4、无合同原因：'){
     formData.applicant_comments = ''
   }
})
</script>

<style scoped>

</style>