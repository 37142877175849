<template>
  <el-form :model="formData" label-width="120px" label-position="top" size="default" >
    <div style="width: 300px">
      <el-row :gutter="10">
        <el-col :md="24">
          <el-form-item label="搜索关键字">
            <el-input v-model="formData.keyword" placeholder="请输入搜索关键字"     clearable @blur="inputting = false; scrollTop()"  @focus="inputting = true" @input="$emit('search')"/>
          </el-form-item>
        </el-col>
        <el-col :md="24" v-if="formData.type == 0">
          <el-form-item label="是否有合同"  >
            <el-radio-group v-model="formData.has_contract" @change="$emit('search')">
              <el-radio border :label="true" >有</el-radio>
              <el-radio border :label="false" >无</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>

        <el-col :md="24">
          <el-form-item label="审批状态"  >
            <el-select
                v-model="formData.status"
                placeholder="请选择审批状态"
                style="width: 100%"
                @change="$emit('search')"
            >
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :md="24" v-if="!isSmall">
          <el-form-item label="申请时间范围"  >
            <el-date-picker
                v-model="formData.range"
                type="daterange"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                value-format="YYYY-MM-DD"
                @change="$emit('search')"
                :shortcuts="shortcuts"
            />
          </el-form-item>
        </el-col>


        <el-col :md="24" v-else>
          <el-form-item label="申请时间范围"  >
            <el-select
                v-model="formData.range"
                placeholder="请选择申请时间范围"
                style="width: 100%"
                @change="$emit('search')"
            >
              <el-option
                  v-for="item in ranges"
                  :key="item.value?.toString()"
                  :label="item.label"
                  :value="item.value?.toString()"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
    <slot/>
  </el-form>
</template>

<script setup>
import {defineProps, reactive, defineEmits} from "vue";
import {useFormTop} from "@/assets/js/formTop";
import {dayjs} from "element-plus";
defineEmits(['search'])
const props = defineProps(['formData', 'isSmall'])
const formData = reactive(props.formData);
const {inputting, scrollTop} = useFormTop();




const options = [
  {
    value: '1',
    label: '待审核',
  },
  {
    value: '-1',
    label: '已驳回',
  },
  {
    value: '2',
    label: '待开票',
  },
  {
    value: '3',
    label: '已开票',
  }
]



const rangesGenerate = (type) => {
  const format = 'YYYY-MM-DD';
  const now = dayjs(); //对象
  const nowFormat = now.format(format);
  switch (type){
    case 0: {
      //当月
      return [now.startOf('month').format(format), nowFormat]
    }
    case 1: {
      //上个月
      const lastMonth = now.subtract(1, 'month');
      return [lastMonth.startOf('month').format(format), lastMonth.endOf('month').format(format)]
    }
    case 2: {
      //近半年
      const last6Month = now.subtract(6, 'month');
      return [last6Month.startOf('month').format(format), nowFormat]
    }
    case 3: {
      //近一年
      const lastYear = now.subtract(1, 'year');
      return [lastYear.startOf('year').format(format), nowFormat]
    }
    case 4: {
      //当年
      return [now.startOf('year').format(format), nowFormat]
    }

  }
  return null;
}

const ranges = [
  {
    value: rangesGenerate(0),
    label: '当月',
  },
  {
    value: rangesGenerate(1),
    label: '上个月',
  },
  {
    value: rangesGenerate(2),
    label: '近半年',
  },
  {
    value: rangesGenerate(3),
    label: '近一年',
  },
  {
    value: rangesGenerate(null),
    label: '最大值',
  }
]





const shortcuts = [
  {
    text: '当月',
    value: rangesGenerate(0),
  },
  {
    text: '上个月',
    value: rangesGenerate(1),
  },
  {
    text: '当年',
    value: rangesGenerate(4),
  },
]
</script>

