<template>
  <el-form :model="formData" label-width="120px" label-position="top">
    <el-form-item label="用户名">
      <el-input v-model="formData.username" placeholder="请输入姓名或手机号" @blur="inputting = false; scrollTop()" @focus="inputting = true" @keyup.enter="$emit('login')"/>
    </el-form-item>
    <el-form-item label="密码">
      <el-input v-model="formData.password" type="password" placeholder="请输入密码" @blur="inputting = false; scrollTop()" @focus="inputting = true" @keyup.enter="$emit('login')"/>
    </el-form-item>
    <el-button type="primary" style="width: 100%;letter-spacing: 2px" @click="$emit('login')" :loading="loading" ref="actionBtn">登录</el-button>
  </el-form>
</template>

<script setup>
import {defineProps, reactive, defineEmits} from "vue";
import {useFormTop} from "@/assets/js/formTop";
defineEmits(['login'])
const props = defineProps(['formData', 'loading'])
const formData = reactive(props.formData);
const {inputting, actionBtn, scrollTop} = useFormTop();

</script>

<style scoped>

</style>