<template>
  <el-card>
    <el-upload
        drag
        :limit="1"
        :action='url'
        :headers="{'apiv': '1.0', 'access_token': access_token}"
        :on-success="success"
        :on-remove="remove"
        :on-error="uploadFileError"
    >
      <el-icon class="el-icon--upload"><upload-filled /></el-icon>
      <div class="el-upload__text">
        拖转到此 或 <em>点击上传</em>
      </div>
    </el-upload>
    <slot/>
  </el-card>
</template>

<script setup>
import {ElNotification} from "element-plus";
import { UploadFilled } from '@element-plus/icons-vue';
import {defineProps} from "vue";
defineProps(['access_token', 'url', 'success', 'remove'])
// const beforeUpload = (rawFile) => {
//   const fileName = rawFile.name;
  // if (!(fileName && [...fileName].reverse().join("").indexOf("fdp") == 0)){
  //   ElNotification({
  //     title: '',
  //     message: '请上传PDF格式文件',
  //     type: 'error',
  //   })
  //   return false;
  // }
// }
const uploadFileError = function (){
  ElNotification({
    title: '',
    message: '文件上传失败，请稍后重试',
    type: 'error',
  })
}
</script>

<style scoped>

</style>